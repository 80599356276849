import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}