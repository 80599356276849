<script setup lang="ts">
const links = [
    {
        label: "Product",
        children: [
            {
                label: "Console",
                to: "/console",
            },
            {
                label: "Pricing",
                to: "/pricing",
            },
            {
                label: "Status",
                to: "https://status.teslemetry.com",
            },
        ],
    },
    {
        label: "Resources",
        children: [
            {
                label: "Documentation",
                to: "/docs",
            },
            {
                label: "Discord",
                to: "https://discord.gg/7wZwHaZbWD",
            },
            {
                label: "Roadmap",
                to: "https://teslemetry.canny.io",
            },
        ],
    },
    {
        label: "Integrations",
        children: [
            {
                label: "Home Assistant Core",
                to: "https://www.home-assistant.io/integrations/teslemetry/",
            },
            {
                label: "Home Assistant Custom",
                to: "https://github.com/Teslemetry/hacs-teslemetry",
            },
            {
                label: "Open Source Libraries",
                to: "https://github.com/search?q=topic%3Alibrary+org%3ATeslemetry&type=Repositories",
            },
        ],
    },
    {
        label: "Company",
        children: [
            {
                label: "About",
                to: "/docs/about/about",
            },
            {
                label: "Terms of Service",
                to: "/docs/about/terms",
            },
            {
                label: "Privacy Policy",
                to: "/docs/about/privacy",
            },
        ],
    },
];
</script>

<template>
    <UFooter>
        <template #top>
            <UFooterColumns :links="links" />
        </template>

        <template #left>
            <p class="text-gray-500 dark:text-gray-400 text-sm">
                Copyright © {{ new Date().getFullYear() }}. All rights
                reserved.
            </p>
        </template>
    </UFooter>
</template>
