import { useLog } from "~/composables/useLog";
export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server || import.meta.dev) return;

  getCurrentUser().then((user) => {
    const event = { p: to.path };
    if (user) event.u = user.uid;
    return useLog(event);
  });
});
