import payload_plugin_scv5vdlNcI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase@10.14.1_rollup@4.28.1_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_jpCablcDy1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DQPA1GonE2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WO5GpZZve3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vGW13sjBge from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.28.1_vite@6.0.3_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_MKk3L0oAYO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_TrD3wxEDWO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lh5fTCmzqv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_eqCLYE477f from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/opt/buildhome/repo/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_r3x0ajYUVy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_zrgnpCsdiT from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.44.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.29.0_@opentelemetry+instru_ugeiemq73i5nbyyuzklstmqfvi/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client_V5dVCis8b1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase@10.14.1_rollup@4.28.1_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/opt/buildhome/repo/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/buildhome/repo/.nuxt/vuefire-plugin.mjs";
import titles_w0fl2OeujU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.13.0_rollup@4.28.1_unhead@1.11.14_vite@6.0.3_vue@3.5.13/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_Vc7rwaYTbW from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.28.1_vite@6.0.3_vue@3.5.13/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_VRrEzhRuOT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.28.1_vite@6.0.3_vue@3.5.13/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_qrEwS45u2P from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_5BfuOWcgsY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_7KER6fvbOV from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_pX6H6msIxR from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@4.28.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_dquQJXCpCL from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.1_rollup@4.28.1_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_dZhyyMt8Bp from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_rrRnwG6ezW from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_6sIOSjXilR from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import paddleJS_client_6OSkSWlVfm from "/opt/buildhome/repo/plugins/paddleJS.client.ts";
import defaults_mJsMDIzgMs from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.13.0_rollup@4.28.1_unhead@1.11.14_vite@6.0.3_vue@3.5.13/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_scv5vdlNcI,
  revive_payload_client_jpCablcDy1,
  unhead_DQPA1GonE2,
  router_WO5GpZZve3,
  _0_siteConfig_vGW13sjBge,
  payload_client_MKk3L0oAYO,
  navigation_repaint_client_TrD3wxEDWO,
  check_outdated_build_client_lh5fTCmzqv,
  chunk_reload_client_eqCLYE477f,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_r3x0ajYUVy,
  sentry_client_zrgnpCsdiT,
  sentry_client_config_o34nk2sJbg,
  plugin_client_V5dVCis8b1,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  titles_w0fl2OeujU,
  siteConfig_Vc7rwaYTbW,
  inferSeoMetaPlugin_VRrEzhRuOT,
  slideovers_qrEwS45u2P,
  modals_5BfuOWcgsY,
  colors_7KER6fvbOV,
  plugin_client_pX6H6msIxR,
  plugin_dquQJXCpCL,
  scrollbars_client_dZhyyMt8Bp,
  presets_rrRnwG6ezW,
  variables_6sIOSjXilR,
  paddleJS_client_6OSkSWlVfm,
  defaults_mJsMDIzgMs
]