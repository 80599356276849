import { useScriptCloudflareWebAnalytics, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const cloudflareWebAnalytics = useScriptCloudflareWebAnalytics({"token":"676c5140a2af4c44b0e2e8242764749e"})
    return { provide: { $scripts: { cloudflareWebAnalytics } } }
  }
})