import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/validate.js";
import log_45global from "/opt/buildhome/repo/middleware/log.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3_vue-tsc@2.1.10/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  log_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/opt/buildhome/repo/middleware/admin.ts"),
  auth: () => import("/opt/buildhome/repo/middleware/auth.ts"),
  ptxn: () => import("/opt/buildhome/repo/middleware/ptxn.ts")
}