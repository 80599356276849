<script setup lang="ts">
import type { NavItem } from "@nuxt/content/dist/runtime/types";
import { useCurrentUser, useFirebaseAuth } from "vuefire";

const auth = useFirebaseAuth();
const user = useCurrentUser();
const router = useRouter();
const logout = () => auth && auth.signOut().then(() => router.push("/"));

const navigation = inject<Ref<NavItem[]>>("navigation", ref([]));

const links = [
  {
    label: "Console",
    to: "/console",
  },
  {
    label: "Documentation",
    to: "/docs",
  },
  {
    label: "Pricing",
    to: "/pricing",
  },
  {
    label: "Partners",
    to: "/partners",
  },
  {
    label: "Blog",
    to: "/blog",
  },
  {
    label: "Status",
    to: "https://status.teslemetry.com",
  },
];
</script>

<template>
  <UHeader :links="links">
    <template #logo> Teslemetry </template>

    <template #right>
      <UColorModeButton size="sm" />

      <ClientOnly>
        <UButton
          v-if="user"
          label="Logout"
          color="gray"
          icon="i-heroicons-arrow-left-20-solid"
          @click="logout"
        >
          <template #leading v-if="user.photoURL">
            <UAvatar :src="user.photoURL" size="2xs" />
          </template>
        </UButton>
        <UButton
          v-else
          label="Login with Tesla"
          icon="i-heroicons-arrow-right-20-solid"
          trailing
          to="/login"
          class="hidden lg:flex"
        />
      </ClientOnly>
    </template>

    <template #panel>
      <UNavigationTree :links="mapContentNavigation(navigation)" default-open />
    </template>
  </UHeader>
</template>
